"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CtiStatusEnum = void 0;
var CtiStatusEnum;
(function (CtiStatusEnum) {
    CtiStatusEnum[CtiStatusEnum["AgentReady"] = 0] = "AgentReady";
    CtiStatusEnum[CtiStatusEnum["AgentOnPause"] = 1] = "AgentOnPause";
    CtiStatusEnum[CtiStatusEnum["AgentLoggedOut"] = 2] = "AgentLoggedOut";
    CtiStatusEnum[CtiStatusEnum["AgentOnWrapup"] = 3] = "AgentOnWrapup";
    CtiStatusEnum[CtiStatusEnum["AgentOnCall"] = 4] = "AgentOnCall";
    CtiStatusEnum[CtiStatusEnum["AgentDialing"] = 5] = "AgentDialing";
    CtiStatusEnum[CtiStatusEnum["AgentRinging"] = 6] = "AgentRinging";
    CtiStatusEnum[CtiStatusEnum["AgentLogin"] = 7] = "AgentLogin";
})(CtiStatusEnum = exports.CtiStatusEnum || (exports.CtiStatusEnum = {}));
