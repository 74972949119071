"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactSheetDataType = exports.ContactSheetActionEnum = void 0;
var ContactSheetActionEnum;
(function (ContactSheetActionEnum) {
    ContactSheetActionEnum["Call"] = "Call";
    ContactSheetActionEnum["Video"] = "Video";
    ContactSheetActionEnum["Chat"] = "Chat";
    ContactSheetActionEnum["Mail"] = "Mail";
    ContactSheetActionEnum["ShareLink"] = "ShareLink";
    ContactSheetActionEnum["VideoInvite"] = "VideoInvite";
    ContactSheetActionEnum["AudioInvite"] = "AudioInvite";
})(ContactSheetActionEnum = exports.ContactSheetActionEnum || (exports.ContactSheetActionEnum = {}));
var ContactSheetDataType;
(function (ContactSheetDataType) {
    ContactSheetDataType["PhoneNumber"] = "PhoneNumber";
    ContactSheetDataType["Mail"] = "Mail";
    ContactSheetDataType["String"] = "String";
    ContactSheetDataType["Url"] = "Url";
})(ContactSheetDataType = exports.ContactSheetDataType || (exports.ContactSheetDataType = {}));
