import _ from 'lodash';

var ctiModule = angular.module('xcCti');

ctiModule.factory('CtiProxy', function ($rootScope, webRtcAudio, errorModal, remoteConfiguration, toast, XucPhoneState, XucLink, $translate, $window, $log, $q) {
  
  var canLog = $q.defer();
  var isUsingWebRtc = function() { return ctiAdapter === webRtcAdapter; };
  var isUa = false;
  var hasMobileApp = false;
  var isUsingUa = function() { return isUa; };
  var isUsingMobileApp = function() {return hasMobileApp; };
  var isUsingCti = function() { return ctiAdapter === Cti; };
  var UnknownDeviceVendor = "unknown";
  var deviceVendor = UnknownDeviceVendor;
  var getDeviceVendor = function() { return deviceVendor; };
  var lineName = "unknown";
  var getLineName = function() { return lineName; };
  var isConferenceCapable = function() { return isUsingWebRtc() || getDeviceVendor() === 'Snom'; };
  var isCustomLine = function() {
    var customLine = isUsingCti() && getLineName().substr(0,6).toLowerCase() == 'local/';
    return customLine;
  };

  var fatalErrorDisplayed = false;

  var onLogout = () => {
    fatalErrorDisplayed = false;
    XucLink.whenLogged().then(onLogin);
  };

  var onLogin = () => {
    XucLink.whenLoggedOut().then(onLogout);
  };
  
  var ctiAdapter = Cti;
  var maxAnswerableCalls = 1;
  var asteriskWsPort = 5039;
  var httpsPort = 443;
  var protocol = $window.location.protocol;

  var FatalError = "fatalError";
  var FatalErrors = {
    WEBRTC_REQUIRES_SSL: 'WEBRTC_REQUIRES_SSL',
    UNABLE_TO_START_WEBRTC: 'UNABLE_TO_START_WEBRTC',
    UNABLE_TO_REGISTER_WEBRTC: 'UNABLE_TO_REGISTER_WEBRTC',
    MISSING_LINE: 'MISSING_LINE',
    MISSING_PERMISSION: 'MISSING_PERMISSION',
    UA_REQUIRES_SSL: 'UA_REQUIRES_SSL'
  };
  var SWITCH_TO_PHONE = 'SWITCH_TO_PHONE';
  var LINECONFIG_PROCESSED = 'LINECONFIG_PROCESSED';

  var audioDisabled;

  var fatalError = function (errorCode) {
    if (!fatalErrorDisplayed) {
      fatalErrorDisplayed = true;
      let modal = errorModal.showErrorModal(errorCode, isUa === true);
      if (modal) {
        modal.result.then(function (logoutReturnState) {
          if (logoutReturnState === false) {
            CtiProxy.toggleUniqueAccountDevice('phone');
            $rootScope.$broadcast(SWITCH_TO_PHONE);
          }
          fatalErrorDisplayed = false;
        });
      }

      stopUsingWebRtc();
      $rootScope.$broadcast(FatalError, errorCode);
    }
  };

  var useWebRTC = function() {
    ctiAdapter = webRtcAdapter;    
    maxAnswerableCalls = 2;
    checkAudioPermission();
    webRtcAudio.enable();
    $rootScope.$broadcast('isUsingMobileApp', hasMobileApp);
  };

  var checkAudioPermission = function() {
    $window.navigator.mediaDevices.getUserMedia({
      "audio": {
        "mandatory": {
          "googAutoGainControl": "false" ,
          "googAutoGainControl2": "false" ,
          "googEchoCancellation": "true" ,
          "googEchoCancellation2": "true" ,
          "googNoiseSuppression": "false" ,
          "googNoiseSuppression2": "false" ,
          "googHighpassFilter": "false" ,
          "googAudioMirroring": "false" 
        }
      }
    }
    )
      .catch((err) => {
        throwMissingPermission(err);
        audioDisabled = true;
      })
      .then((stream) => {
        if (stream !== undefined) {
          audioDisabled = false;
          stream.getTracks().forEach(track => track.stop());
        }
      });
  };

  var useCti = function() {
    ctiAdapter = Cti;
    maxAnswerableCalls = 1;
  };

  var agentLoginIsPossible = () => {
    return canLog.promise;
  };

  var isInLocalhostEnvironment = (hostname) => {
    return hostname === '127.0.0.1' || hostname === 'localhost';
  };

  let throwWebrtcWithoutSSLError = () => {
    canLog = $q.defer();
    $log.warn('Cannot use WebRtc without SSL - aborting WebRtc initialization');
    fatalError(FatalErrors.WEBRTC_REQUIRES_SSL);
    stopUsingWebRtc();
  };

  let startWebrtcLine = (usingSsl, xucHost, lineCfg) => {
    canLog.resolve();
    useWebRTC();
    const actualPort = $window.location.port === '' ? httpsPort : $window.location.port;
    const port = usingSsl ? actualPort : asteriskWsPort;
    const address = usingSsl ? xucHost : lineCfg.xivoIp;
    xc_webrtc.setCustomLogger($log);
    xc_webrtc.initByLineConfig(lineCfg, 'XiVO Assistant', usingSsl, port, XucLink.getXucToken(), 'audio_remote', address);
  };

  let throwMissingPermission = (err) => {
    $log.error("User denied audio permission, WebRTC will not work until autorized", err);
    fatalError(FatalErrors.MISSING_PERMISSION);
  };

  let throwMissingLineError = () => {
    $log.warn('User without line, logging out');
    fatalError(FatalErrors.MISSING_LINE);
  };

  let throwUAUserWithoutSSLError = () => {
    canLog = $q.defer();
    $log.warn('Cannot use Unique account without SSL - aborting initialization');
    fatalError(FatalErrors.UA_REQUIRES_SSL);
  };

  let startDeskphoneLine = (lineCfg) => {
    if (lineCfg.hasDevice && lineCfg.webRtc) {
      $log.info("Line has both, device and webrtc, using device");
    } else {
      $log.info("Line with device - CtiProxy will use standard Cti");
    }
    if (typeof (lineCfg.vendor) !== "undefined") {
      deviceVendor = lineCfg.vendor;
    }
    canLog.resolve();
  };

  let processLineCfg = (lineCfg) => {
    $log.debug('LineCfg: ', lineCfg);
    deviceVendor = UnknownDeviceVendor;
    lineName = lineCfg.name;
    isUa = lineCfg.isUa || false;
    hasMobileApp = lineCfg.mobileApp || false;
    const usingSsl = protocol === 'https:';
    const xucHost = $window.externalConfig.host;
    if (lineCfg.webRtc && !lineCfg.hasDevice) {
      $log.info("WebRTC line without device - CtiProxy will use WebRTC");
      if (usingSsl || isInLocalhostEnvironment($window.location.hostname)) {
        startWebrtcLine(usingSsl, xucHost , lineCfg);
        $rootScope.$broadcast(LINECONFIG_PROCESSED);
      } else {
        throwWebrtcWithoutSSLError();
      }
    } else {
      if (lineCfg.id === '-' && !remoteConfiguration.isAgent()) {
        throwMissingLineError();
      } else if (isUsingUa() && !(usingSsl || isInLocalhostEnvironment($window.location.hostname))) {
        throwUAUserWithoutSSLError();
      } else {
        startDeskphoneLine(lineCfg);
        $rootScope.$broadcast(LINECONFIG_PROCESSED);
      }
      stopUsingWebRtc();
    }
  };

  const getSipCallId = (uniqueId) => {
    return _.get(_.find(XucPhoneState.getCalls(),(call) => {
      return call.uniqueId == uniqueId;
    }), 'userData.SIPCALLID');
  };

  var webRtcAdapter = {
    dial : function(destination, variables) {
      if (variables) { $log.warn("Dial using webRTC does not propagate variables"); }
      if (audioDisabled == true) {
        toast({
          duration: 3000,
          message: $translate.instant('webRTCAudioDisabled'),
          className: 'alert-danger',
          position: "center",
          container: '.toast-container'
        });
      } else {
        Cti.dial(destination);
      }
    },
    hangup : function(uniqueId) { Cti.hangup(uniqueId); },
    answer : function(uniqueId) { xc_webrtc.answerBySipCallId(getSipCallId(uniqueId)); },
    attendedTransfer: function(destination) {Cti.attendedTransfer(destination); },
    hold : function(uniqueId) { xc_webrtc.holdBySipCallId(getSipCallId(uniqueId)); },
    toggleMicrophone : function(uniqueId) { xc_webrtc.toggleMicrophone(getSipCallId(uniqueId)); },
    conference : function() { xc_webrtc.conference(); },
    conferenceMuteMe: function(conferenceNumber) { Cti.conferenceMuteMe(conferenceNumber); },
    conferenceUnmuteMe: function(conferenceNumber) { Cti.conferenceUnmuteMe(conferenceNumber); },
    conferenceMuteAll: function(conferenceNumber) { Cti.conferenceMuteAll(conferenceNumber); },
    conferenceUnmuteAll: function(conferenceNumber) { Cti.conferenceUnmuteAll(conferenceNumber); },
    conferenceMute: function(conferenceNumber, index) { Cti.conferenceMute(conferenceNumber, index); },
    conferenceUnmute: function(conferenceNumber, index) { Cti.conferenceUnmute(conferenceNumber, index); },
    conferenceKick: function(conferenceNumber, index) { Cti.conferenceKick(conferenceNumber, index); },
    conferenceDeafen: function(conferenceNumber, index) { Cti.conferenceDeafen(conferenceNumber, index); },
    conferenceUndeafen: function(conferenceNumber, index) { Cti.conferenceUndeafen(conferenceNumber, index); },
    conferenceInvite: function(conferenceNumber, exten, role, earlyJoin,  variables, marked, leaveWhenLastMarkedLeave, callerId) { Cti.conferenceInvite(conferenceNumber, exten, role, earlyJoin,  variables, marked, leaveWhenLastMarkedLeave, callerId); },
    includeToConference: function(role, marked, leaveWhenLastMarkedLeave, callerId) {Cti.includeToConference(role, marked, leaveWhenLastMarkedLeave, callerId);}
  };

  var registerWebRtcHandlers = function() {
    var webRtcGeneralEventHandler = function(event) {
      $log.log('webRtcGeneralEventHandler' + JSON.stringify(event));
      $rootScope.$broadcast(event.type, event);
    };
    var webRtcRegistrationEventHandler = function(event) {
      $log.log('webRtcRegistrationEventHandler' + JSON.stringify(event));
      $rootScope.$broadcast(event.type, event);
    };
    var webRtcIncomingEventHandler = function(event) {
      $log.log('webRtcIncomingEventHandler' + JSON.stringify(event));
    };
    var webRtcOutgoingEventHandler = function(event) {
      $log.log('webRtcOutgoingEventHandler' + JSON.stringify(event));
    };

    xc_webrtc.clearHandlers();
    xc_webrtc.setHandler(xc_webrtc.MessageType.GENERAL, webRtcGeneralEventHandler);
    xc_webrtc.setHandler(xc_webrtc.MessageType.REGISTRATION, webRtcRegistrationEventHandler);
    xc_webrtc.setHandler(xc_webrtc.MessageType.INCOMING, webRtcIncomingEventHandler);
    xc_webrtc.setHandler(xc_webrtc.MessageType.OUTGOING, webRtcOutgoingEventHandler);
  };

  var webRtcFailed = () => {
    if (isUsingUa()) {
      CtiProxy.toggleUniqueAccountDevice('phone');
    } else {
      fatalError(FatalErrors.UNABLE_TO_START_WEBRTC);
    }
  };

  var webRtcUnregister = () => {
    fatalError(FatalErrors.UNABLE_TO_REGISTER_WEBRTC);
  };

  var onCtiLoggedOn = function() {
    try {
      Cti.unsetHandler(Cti.MessageType.LOGGEDON, CtiProxy.onCtiLoggedOn);
      registerWebRtcHandlers();
      Cti.setHandler(Cti.MessageType.LINECONFIG, processLineCfg);
      Cti.getConfig('line');
    } catch(e) {
      $log.error("ctiProxy error", e);
    }
  };

  var stopUsingWebRtc = function() {
    turnOffAudio();
    useCti();
    $rootScope.$broadcast('isUsingMobileApp', false);
  };
  
  function turnOffAudio() {
    if (isUsingWebRtc()) {
      xc_webrtc.stop();
    }
    webRtcAudio.disable();
  }

  var updateLine = function() {
    Cti.getConfig('line');
  };

  var getMaxAnswerableCalls = function() {
    return maxAnswerableCalls;
  };


  var disableMediaKeys = function () {
    //https://developers.google.com/web/updates/2019/02/chrome-73-media-updates
    if ($window.navigator.mediaSession !== undefined) {
      $window.navigator.mediaSession.setActionHandler('play', function () {
        angular.noop();
      });
      $window.navigator.mediaSession.setActionHandler('pause', function () {
        angular.noop();
      });
    }
  };

  var CtiProxy = {
    dial : function(destination, variables) {
      if (!isUsingWebRtc() || XucPhoneState.getCalls().length < 2) {
        $rootScope.$broadcast('dialingNumber', destination);
        ctiAdapter.dial(destination, variables);
      }
    },
    dialByUsername: function(username, variables) { Cti.dialByUsername(username, variables); },
    hangup : function(uniqueId) { ctiAdapter.hangup(uniqueId); },
    answer : function(uniqueId) { ctiAdapter.answer(uniqueId); },
    hold : function(uniqueId) { ctiAdapter.hold(uniqueId); },
    toggleMicrophone : function(uniqueId) { ctiAdapter.toggleMicrophone(uniqueId);},
    conference : function() { ctiAdapter.conference(); },
    conferenceMuteMe: function(conferenceNumber) { ctiAdapter.conferenceMuteMe(conferenceNumber); },
    conferenceUnmuteMe: function(conferenceNumber) { ctiAdapter.conferenceUnmuteMe(conferenceNumber); },
    conferenceMuteAll: function(conferenceNumber) { ctiAdapter.conferenceMuteAll(conferenceNumber); },
    conferenceUnmuteAll: function(conferenceNumber) { ctiAdapter.conferenceUnmuteAll(conferenceNumber); },
    conferenceMute: function(conferenceNumber, index) { ctiAdapter.conferenceMute(conferenceNumber, index); },
    conferenceUnmute: function(conferenceNumber, index) { ctiAdapter.conferenceUnmute(conferenceNumber, index); },
    conferenceKick: function(conferenceNumber, index) { ctiAdapter.conferenceKick(conferenceNumber, index); },
    conferenceDeafen: function(conferenceNumber, index) { ctiAdapter.conferenceDeafen(conferenceNumber, index); },
    conferenceUndeafen: function(conferenceNumber, index) { ctiAdapter.conferenceUndeafen(conferenceNumber, index); },
    conferenceInvite: function(conferenceNumber, exten, role, earlyJoin, variables, marked, leaveWhenLastMarkedLeave, callerId) { ctiAdapter.conferenceInvite(conferenceNumber, exten, role, earlyJoin, variables, marked, leaveWhenLastMarkedLeave, callerId); },
    includeToConference: function(role, marked, leaveWhenLastMarkedLeave, callerId) {Cti.includeToConference(role, marked, leaveWhenLastMarkedLeave, callerId);},
    attendedTransfer : function(destination) {
      $rootScope.$broadcast('dialingNumber', destination);
      ctiAdapter.attendedTransfer(destination); 
    },
    directTransfer : function(destination) { Cti.directTransfer(destination); },
    completeTransfer : function() { Cti.completeTransfer(); },
    cancelTransfer : function() { Cti.cancelTransfer(); },
    unregisterMobileApp: function() {Cti.unregisterMobileApp(); },

    dtmf : function(digit) {
      if (isUsingWebRtc()) {
        xc_webrtc.dtmf(digit);
      } else if (isUsingCti()) {
        $log.warn("CtiProxy is using standard Cti - DTMF sending is not supported");
      } else {
        $log.warn("CtiProxy is not initialized");
      }
    },

    isUsingWebRtc: isUsingWebRtc,
    isUsingUa: isUsingUa,
    isUsingMobileApp: isUsingMobileApp,
    isUsingCti: isUsingCti,
    stopUsingWebRtc: stopUsingWebRtc,
    getDeviceVendor: getDeviceVendor,
    getMaxAnswerableCalls: getMaxAnswerableCalls,
    isConferenceCapable: isConferenceCapable,
    isCustomLine: isCustomLine,
    disableMediaKeys: disableMediaKeys,
    agentLoginIsPossible: agentLoginIsPossible,
    toggleUniqueAccountDevice: function(device) { Cti.toggleUniqueAccountDevice(device); },
    displayNameLookup: function(username) { Cti.displayNameLookup(username); },

    UnknownDeviceVendor: UnknownDeviceVendor,
    FatalError: FatalError,
    FatalErrors: FatalErrors,
    SWITCH_TO_PHONE: SWITCH_TO_PHONE,
    LINECONFIG_PROCESSED: LINECONFIG_PROCESSED,

    updateLine: updateLine,

    _testProcessLineCfg: processLineCfg,
    _setProtocolForTest: function(p) { protocol = p; }
  };

  var init = function() {
    useCti();
    $rootScope.$on('ctiLoggedOn', onCtiLoggedOn);
    $rootScope.$on("Failed", webRtcFailed);
    $rootScope.$on("Unregistered", webRtcUnregister);
    XucLink.whenLogged().then(onLogin);
  };
  init();

  return CtiProxy;
});
