import _ from 'lodash';

export default function XucAgentUser($rootScope, $q, XucUser, CtiProxy, XucAgent, XucQueue, XucLink, $log, $timeout, CtiStatusService) {
  let _agentErrorDefer = $q.defer();
  let _currentAgentState = null;

  let _timeout;
  let _timeoutms = 5000;
  let _setQueueTimeoutMs = function (val) {
    _timeoutms = val;
  };
  let _pendingRequests = {};
  let _isListened = false;

  let _getQueuesAsync = function () {
    return XucUser.getUserAsync().then(function (user) {
      return XucAgent.getAgentAsync(user.agentId);
    }).then(function (agent) {
      let results = [];
      angular.forEach(agent.queueMembers, function (penalty, queueId) {
        results.push(XucQueue.getQueueAsync(queueId).then(function (queue) {
          let q = angular.copy(queue);
          q.penalty = penalty;
          return q;
        }));
      });
      return $q.all(results);
    });
  };

  let _subscribeToAgentState = function (scope, callback) {
    let handler = $rootScope.$on('UserAgentState', function (event, state) {
      if ($rootScope.$$phase) {
        callback(state);
      } else {
        scope.$apply(function () {
          callback(state);
        });
      }
    });
    if (_currentAgentState !== null) {
      callback(_currentAgentState);
    }
    scope.$on('$destroy', handler);
  };

  let _switchAgentState = function (state) {
    $log.debug("Switching agent state", state);
    if (state.status !== undefined) {
      CtiStatusService.switchAgentStateCtiStatus(state.status, state.name);
    } else {
      CtiStatusService.switchAgentStateCtiStatus(CtiStatusService.mapAgentStateToCtiStatus(state.name), state.userStatus && state.userStatus.name ? state.userStatus.name : "");
    }
  };

  let _onAgentStateEvent = function (event) {
    XucUser.getUserAsync().then(function (user) {
      if (event.agentId === user.agentId) {
        if (event.name === "AgentOnPause") {
          event.causeName = XucAgent.getUserStatusDisplayName(event.cause);
        }
        _currentAgentState = event;
        if (_isListened) {
          _isListened = false;
          $rootScope.$emit("AgentListenEvent", {started: false, phoneNumber: event.phoneNb, agentId: user.agentId});
        }
        $rootScope.$emit("UserAgentState", event);
      }
    });
  };

  let _onAgentError = function (event) {
    _agentErrorDefer.resolve(event);
  };

  let _whenAgentError = function () {
    return _agentErrorDefer.promise;
  };

  let _loginAgent = function () {
    $q.all([XucLink.whenLogged(), XucUser.getUserAsync(), CtiProxy.agentLoginIsPossible()]).then(function () {
      Cti.loginAgent(XucLink.getPhoneNumber());
      Cti.getAgentStates();
    });
  };

  let _logoutAgent = function () {
    $q.all([XucLink.whenLogged(), XucUser.getUserAsync()]).then(function () {
      Cti.logoutAgent();
    });
  };

  let _init = function () {
    $log.info("Starting XucAgentUser service");
    XucLink.whenLoggedOut().then(_uninit);
  };

  var _uninit = function() {
    $log.info("Unloading XucAgentUser service");
    _agentErrorDefer = $q.defer();
    _currentAgentState = null;
    _isListened = false;
    XucLink.whenLogged().then(_init);
  };

  let _joinQueue = function (queueId, penalty) {
    XucUser.getUserAsync().then(function (user) {
      Cti.setAgentQueue(user.agentId, queueId, penalty);
    });
  };

  let _leaveQueue = function (queueId) {
    XucUser.getUserAsync().then(function (user) {
      Cti.removeAgentFromQueue(user.agentId, queueId);
    });
  };

  let _isMemberOfQueueAsync = function (queueId) {
    return XucUser.getUserAsync().then(function (user) {
      return XucAgent.getAgentAsync(user.agentId);
    }).then(function (agent) {
      return typeof (agent.queueMembers[queueId]) !== "undefined";
    });
  };

  let _joinQueueAsync = function (queueId, penalty) {
    return XucUser.getUserAsync().then(function (user) {
      let deferred = _addPendingRequest(queueId);
      Cti.setAgentQueue(user.agentId, queueId, penalty);
      return deferred.promise;
    });
  };

  let _leaveQueueAsync = function (queueId) {
    return XucUser.getUserAsync().then(function (user) {
      let deferred = _addPendingRequest(queueId);
      Cti.removeAgentFromQueue(user.agentId, queueId);
      return deferred.promise;
    });
  };

  let _onQueueMemberEvent = function (queueMember) {
    if (_.has(_pendingRequests, queueMember.queueId)) {
      if (_timeout) $timeout.cancel(_timeout);
      let p = _pendingRequests[queueMember.queueId];
      p.resolve(queueMember.queueId);
      delete _pendingRequests[queueMember.queueId];
    }
  };

  var _addPendingRequest = function(queueId) {
    _pendingRequests[queueId] = $q.defer();
    _timeout = $timeout(function() { _timeoutRequest(queueId); }, _timeoutms);
    return _pendingRequests[queueId];
  };

  var _timeoutRequest = function(queueId) {
    if(_.has(_pendingRequests, queueId)) {
      let p = _pendingRequests[queueId];
      delete _pendingRequests[queueId];
      p.reject({"error": "timeout"});
    }
  };

  let _onAgentListened = function (evt) {
    _isListened = evt.started;
    $rootScope.$emit("AgentListenEvent", evt);
  };

  let _subscribeToListenEvent = function (scope, callback) {
    let handler = $rootScope.$on('AgentListenEvent', function (event, state) {
      if ($rootScope.$$phase) {
        callback(state);
      } else {
        scope.$apply(function () {
          callback(state);
        });
      }
    });

    scope.$on('$destroy', handler);
  };

  let _canMonitor = function () {
    if (_currentAgentState != null
        && (_currentAgentState.monitorState == "ACTIVE" || _currentAgentState.monitorState == "PAUSED")) {
      return true;
    }
    return false;
  };

  let _isMonitored = function () {
    if (_currentAgentState != null
        && _currentAgentState.monitorState == "ACTIVE") {
      return true;
    }
    return false;
  };

  let _monitorPause = function () {
    XucUser.getUserAsync().then(function (user) {
      Cti.monitorPause(user.agentId);
    });
  };

  let _monitorUnpause = function () {
    XucUser.getUserAsync().then(function (user) {
      Cti.monitorUnpause(user.agentId);
    });
  };

  Cti.setHandler(Cti.MessageType.AGENTSTATEEVENT, _onAgentStateEvent);
  Cti.setHandler(Cti.MessageType.AGENTERROR, _onAgentError);
  Cti.setHandler(Cti.MessageType.QUEUEMEMBER, _onQueueMemberEvent);
  Cti.setHandler(Cti.MessageType.AGENTLISTEN, _onAgentListened);

  XucLink.whenLogged().then(_init);

  return {
    getQueuesAsync: _getQueuesAsync,
    isMemberOfQueueAsync: _isMemberOfQueueAsync,
    subscribeToAgentState: _subscribeToAgentState,
    subscribeToListenEvent: _subscribeToListenEvent,
    loginAgent: _loginAgent,
    logoutAgent: _logoutAgent,
    joinQueue: _joinQueue,
    joinQueueAsync: _joinQueueAsync,
    leaveQueue: _leaveQueue,
    leaveQueueAsync: _leaveQueueAsync,
    setQueueTimeoutMs: _setQueueTimeoutMs,
    switchAgentState: _switchAgentState,
    whenAgentError: _whenAgentError,
    canMonitor: _canMonitor,
    isMonitored: _isMonitored,
    monitorPause: _monitorPause,
    monitorUnpause: _monitorUnpause
  };
}

