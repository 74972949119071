"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallHistoryService = void 0;
const CallHistory_model_1 = require("../models/CallHistory.model");
const lodash_1 = require("lodash");
class CallHistoryService {
    constructor($filter) {
        this.$filter = $filter;
    }
    MapCallHistoryModelToCallHistoryViewModel(history) {
        let newHistory = [];
        history.forEach((item) => {
            let newCall = {
                callStatus: item.status,
                number: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstNum : item.srcNum,
                firstName: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstFirstName : item.srcFirstName,
                lastName: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstLastName : item.srcLastName,
                phoneStatus: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstPhoneStatus : item.srcPhoneStatus,
                videoStatus: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstVideoStatus : item.srcVideoStatus,
                userName: item.status === CallHistory_model_1.CallStatus.Emitted ? item.dstUsername : item.srcUsername,
                initials: "",
                day: item.start,
                duration: item.duration
            };
            if (newCall.firstName !== null && newCall.firstName !== '')
                newCall.initials += newCall.firstName.charAt(0);
            if (newCall.initials !== '')
                newCall.initials += ' ';
            if (newCall.lastName !== null && newCall.lastName !== '')
                newCall.initials += newCall.lastName.charAt(0);
            newHistory.push(newCall);
        });
        let filteredCallHistory = {};
        newHistory.filter(elem => elem.number !== null).forEach((call) => {
            if (!lodash_1.isNil(call.day)) {
                const date = this.$filter('date')(new Date(call.day), "yyyy-MM-dd");
                let dateIndex = date.toString();
                if (!filteredCallHistory[dateIndex]) {
                    filteredCallHistory[dateIndex] = {};
                }
                let userIndex = call.userName ? call.userName : call.number;
                if (!filteredCallHistory[dateIndex][userIndex]) {
                    filteredCallHistory[dateIndex][userIndex] = [];
                }
                filteredCallHistory[dateIndex][userIndex].push(call);
            }
        });
        return filteredCallHistory;
    }
}
exports.CallHistoryService = CallHistoryService;
