import _, {isNil} from 'lodash';
import {ContactSheetActionEnum} from "ucassistant/models/contact-sheet.model";

export default class ContactsController {

  constructor($rootScope, $scope, $state, $stateParams, XucDirectory, $translate, applicationConfiguration, toast, $uibModal, ContactService) {

    $scope.$on('contactSheetUpdated', function() {
      $scope.contactSheet = _.each(XucDirectory.getContactSheetResult(), getInitials);
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('favoriteContactSheetUpdated', function() {
      $scope.favoriteContactSheet = _.each(XucDirectory.getFavoriteContactSheet(), getInitials);
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('DirectoryLookUp', function(event, term) {
      $scope.search(term);
    });

    $scope.search = function(term) {
      Cti.searchContacts(term);
      $scope.showSearch = true;
    };

    $scope.getFavorites = function() {
      Cti.getFavoriteContacts();
    };

    $scope.addFavorite = function(contact, $event) {
      $event.stopPropagation();
      Cti.addFavorite(contact.sources[0].id, contact.sources[0].name);
      contact.isFavorite = true;
    };

    $scope.removeFavorite = function(contact, $event) {
      $event.stopPropagation();
      Cti.removeFavorite(contact.sources[0].id, contact.sources[0].name);
      contact.isFavorite = false;
    };

    $scope.init = function() {
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
      if ($stateParams.showFavorites === false) {
        $scope.showSearch = true;
      } else {
        $scope.getFavorites();
        $scope.showSearch = false;
      }

      if (typeof($stateParams.search) != 'undefined') {
        $scope.search($stateParams.search);
      }
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
    };

    $scope.goToChat = (contact) => {
      let username = contact.actions.Chat ? contact.actions.Chat.args[0] : '';
      $state.go($scope.appConfig.routing + '.conversation', {remoteParty: username});
    };

    const getInitials = function (contact) {
      if (contact.name && contact.name.length > 0) {
        contact.initials = contact.name.split(' ').map(part => getInitial(part)).slice(0, 4).join(' ');
      }
    };

    const getTranslationType = function (type) {
      switch (type) {
      case 'meetingroom':
        return 'MEETINGROOM_COPIED';
      case 'phone':
        return 'PHONE_COPIED';
      case 'email':
        return 'EMAIL_COPIED';
      }
    };

    $scope.clipboardPopup = function (kind, $event) {
      $event.stopPropagation();
      toast({
        duration: 3000,
        message: $translate.instant(getTranslationType(kind)),
        className: 'alert-copied',
        position: 'center',
        container: '.toast-container'
      });
    };

    $scope.generateMeetingShareLink = (contact) => ContactService.generateMeetingShareLink(contact);

    const getInitial = (name) => {
      if (name && name.length > 0) return name[0];
      else return '';
    };

    $scope.removePopUpContainer = () => {
      angular.element(document.getElementsByClassName('angularjs-toast')).remove();
    };

    $scope.openContactSheet = (contact) => {
      if (document.getElementsByClassName('contact-sheet-modal').length === 0) {
        $scope.removePopUpContainer();
        $uibModal.open({
          templateUrl: 'assets/javascripts/ucassistant/controllers/contact-sheet.controller.html',
          controller: 'contactSheetController',
          windowClass: 'contact-sheet-modal',
          backdrop: false,
          resolve: {
            contact : () => contact
          },
        }).result.then(() => {}, () => {});
      }

    };


    $scope.actionsProperties = {
      'Edit': {
        class: 'edit',
        id: 'edit-contact-button',
        icon: `<span class='nd-action-button'><i class='xivo-modifier'></i></span>`,
        title: 'EDIT',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.navigateToContactEditor(contact);
        },
        displayCondition: (contact) => contact.isPersonal
      },
      'Call': {
        class: 'call',
        id: 'call-contact-button',
        icon: `<span class="nd-action-button"><i class='xivo-telephone'></i></span>`,
        title: 'PHONE_CALL',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.makeACall(contact, $event);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Call]) && ContactService.userWithCallIcon(contact) || ContactService.meetingRoomWithCallIcon(contact)
      },
      'Video': {
        class: 'video-call',
        id: 'video-contact-button',
        icon: `<span class="nd-action-button"><i class="xivo-appel-visio"></i></span>`,
        title: 'P2P_VIDEO_CALL',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          contact.isMeetingroom ? ContactService.join(contact, $event) : ContactService.startPointToPointVideoCall(contact, $event);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Video]) && ContactService.isJitsiAvailable() && !ContactService.canInviteToMeetingRoom(contact)
      },
      'Chat': {
        class: 'comments',
        id: 'chat-contact-button',
        icon: `<span class="nd-action-button"><i class="xivo-message"></i></span>`,
        title: 'CHAT',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          $scope.goToChat(contact);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Chat])
      },
      'Mail': {
        class: '',
        id: 'mail-contact-button',
        icon: `<span class="nd-action-button"><i class='fas fa-envelope'></i></span>`,
        title: 'SEND_EMAIL',
        onClick: (contact, $event) => {
          $event.stopPropagation(); window.location.href = ContactService.generateMailToLink(contact);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Mail])
      },
      'ShareLink': {
        class: 'share',
        id: 'sharelink-contact-button',
        icon: `<span class='nd-action-button'><i class='fas fa-share-alt'></i></span>`,
        title: 'SHARE_LINK',
        onClick: (contact, $event) => {
          $event.stopPropagation(); navigator.clipboard.writeText(ContactService.generateMeetingShareLink(contact)); $scope.clipboardPopup('meetingroom', $event);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.ShareLink])
      },
      'VideoInvite': {
        class: 'video-call',
        id: 'videoinvite-contact-button',
        icon: `
        <span class='nd-action-button'>
          <div class="fa-users-plus-wrapper">
            <span><i class="fa fa-users fa-users-plus-0"></i></span>
            <i class="fa fa-plus fa-users-plus-1" aria-hidden="true"></i>
          </div>
        </span>
        `,
        title: 'INVITE_TO_MEETING_ROOM',
        onClick: (contact, $event) => {
          $event.stopPropagation(); ContactService.inviteToMeetingRoom(contact);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.VideoInvite]) && ContactService.isJitsiAvailable() && ContactService.canInviteToMeetingRoom(contact),
      },
      'AudioInvite': {
        class: 'audio-invite',
        id: 'audioinvite-contact-button',
        icon: `
        <span class='nd-action-button invite-to-conference-icon'>
          <span class="xivo-phone-plus-wrapper">
            <span><i class="xivo-telephone"></i></span>
            <i class="fa fa-plus xivo-phone-plus" aria-hidden="true"></i>
            </i>
          </span>
        </span>`,
        title: 'INVITE_TO_CONFERENCE',
        onClick: (contact, $event) => {$event.stopPropagation(); ContactService.inviteToConference(contact, $event);},
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.AudioInvite]) && ContactService.userWithInviteToConferenceIcon(contact) || ContactService.meetingRoomWithInviteToConferenceIcon(contact),
      }
    };

    $scope.ordering = ['Edit', 'ShareLink', 'Mail', 'Chat', 'VideoInvite', 'Video', 'AudioInvite', 'Call'];

    $scope.init();
  }
}
