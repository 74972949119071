import {ContactSheetActionEnum} from "ucassistant/models/contact-sheet.model";
import {isNil} from 'lodash';

export default class ContactSheetController {

  constructor($scope,contact,ContactService, $uibModalInstance, $state,applicationConfiguration, toast, $translate, XucLink) {
    $scope['appConfig'] = applicationConfiguration.getCurrentAppConfig();
    $scope['contact'] = contact;


    $scope.contactSheetisSectionEmpty = (contact) => {
      contact.details.forEach(section => {
        section.collapsed=true;
        section.fields.forEach(field => {
          if (field.data !== "") {
            section.collapsed = false;
          }
        });
      });
    };

    $scope.toggleSection = (section) => {
      section.collapsed = !section.collapsed;
    };

    $scope.contactSheetisSectionEmpty(contact);

    $scope.contactSheetIsMeetingRoom = (contact) => ContactService.isMeetingRoom(contact);

    $scope.contactSheetAddFavorite = (contact, $event) => {
      $event.stopPropagation();
      contact.isFavorite = true;
      Cti.addFavorite(contact.sources[0].id, contact.sources[0].name);
    };

    $scope.contactSheetRemoveFavorite = (contact, $event) => {
      $event.stopPropagation();
      contact.isFavorite = false;
      Cti.removeFavorite(contact.sources[0].id, contact.sources[0].name);
    };

    $scope.contactSheetGetPhoneStateBackColor = (contact) => ContactService.getPhoneStateBackColor(contact);

    $scope.contactSheetGetPhoneStateLabel = (contact) => ContactService.getPhoneStateLabel(contact);

    $scope.contactSheetOnClose = () => $uibModalInstance.dismiss(true);

    const getTranslationType = (field) => {
      switch (field) {
      case 'meetingroom':
        return $translate.instant('MEETINGROOM_COPIED');
      case 'PhoneNumber':
        return $translate.instant('PHONE_COPIED');
      case 'Mail':
        return $translate.instant('EMAIL_COPIED');
      default:
        return $translate.instant('VALUE_COPIED', {value: field.name});
      }
    };

    $scope.contactSheetClipboardCopy = (field, $event) => {
      navigator.clipboard.writeText(field.data);
      $scope.contactSheetClipboardPopup(field, $event);
    };

    $scope.contactSheetClipboardPopup = (field, $event) => {
      $event.stopPropagation();
      toast({
        duration: 3000,
        message: getTranslationType(field),
        className: 'alert-copied',
        position: 'center',
        container: '#toast-container-modal-id'
      });
    };

    $scope.contactSheetGetFieldDataFormatted = (contact, field) => ContactService.getFieldDataFormatted(contact, field);

    $scope.contactSheetGetFieldMailToLink = (contact) => ContactService.generateMailToLink(contact);

    $scope.contactSheetMakeACall = (phoneNumber , $event) => {
      ContactService.makeACall($scope.contact, $event, phoneNumber);
      $scope.contactSheetOnClose();
    };

    $scope.getContextualPhoneClass = (contact) => ContactService.getContextualPhoneClass(contact);
    $scope.getContextualPhoneTooltip = (contact) => (ContactService.getContextualPhoneClass(contact) + "-tooltip").replaceAll("-","_").toUpperCase();
    $scope.execContextualPhoneAction = (contact, number, $event) => {
      ContactService.execContextualPhoneAction(contact, number, $event);
      $scope.contactSheetOnClose();
    };

    XucLink.whenLoggedOut().then($scope.contactSheetOnClose());

    $scope.actionsProperties = {
      'Edit': {
        class: 'edit',
        id: 'edit-contact-button',
        icon: `<span class='nd-action-button'><i class='xivo-modifier'></i></span>`,
        title: 'EDIT',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.navigateToContactEditor(contact);
        },
        displayCondition: (contact) => contact.isPersonal
      },
      'Call': {
        class: 'call',
        id: 'call-contact-button',
        icon: `<span class="nd-action-button"><i class='xivo-telephone'></i></span>`,
        title: 'PHONE_CALL',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.makeACall(contact, $event);
          $scope.contactSheetOnClose();
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Call]) && ContactService.userWithCallIcon(contact) || ContactService.meetingRoomWithCallIcon(contact)
      },
      'Video': {
        class: 'video-call',
        id: 'video-contact-button',
        icon: `<span class="nd-action-button"><i class="xivo-appel-visio"></i></span>`,
        title: 'P2P_VIDEO_CALL',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          contact.isMeetingroom ? ContactService.join(contact, $event) : ContactService.startPointToPointVideoCall(contact, $event);
          $scope.contactSheetOnClose();
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Video]) && ContactService.isJitsiAvailable() && !ContactService.canInviteToMeetingRoom(contact)
      },
      'Chat': {
        class: 'comments',
        id: 'chat-contact-button',
        icon: `<span class="nd-action-button"><i class="xivo-message"></i></span>`,
        title: 'CHAT',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          let username = contact.actions.Chat ? contact.actions.Chat.args[0] : '';
          $state.go($scope.appConfig.routing + '.conversation', {remoteParty: username});
          $scope.contactSheetOnClose();
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Chat])
      },
      'Mail': {
        class: '',
        id: 'mail-contact-button',
        icon: `<span class="nd-action-button"><i class='fas fa-envelope'></i></span>`,
        title: 'SEND_EMAIL',
        onClick: (contact, $event) => {
          $event.stopPropagation(); window.location.href = ContactService.generateMailToLink(contact);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.Mail])
      },
      'ShareLink': {
        class: 'share',
        id: 'sharelink-contact-button',
        icon: `<span class='nd-action-button'><i class='fas fa-share-alt'></i></span>`,
        title: 'SHARE_LINK',
        onClick: (contact, $event) => {
          $event.stopPropagation(); navigator.clipboard.writeText(ContactService.generateMeetingShareLink(contact)); $scope.contactSheetClipboardPopup('meetingroom', $event);
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.ShareLink])
      },
      'VideoInvite': {
        class: 'video-call',
        id: 'videoinvite-contact-button',
        icon: `
        <span class='nd-action-button'>
          <div class="fa-users-plus-wrapper">
            <span><i class="fa fa-users fa-users-plus-0"></i></span>
            <i class="fa fa-plus fa-users-plus-1" aria-hidden="true"></i>
          </div>
        </span>
        `,
        title: 'INVITE_TO_MEETING_ROOM',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.inviteToMeetingRoom(contact);
          $scope.contactSheetOnClose();
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.VideoInvite]) && ContactService.isJitsiAvailable() && ContactService.canInviteToMeetingRoom(contact),
      },
      'AudioInvite': {
        class: 'audio-invite',
        id: 'audioinvite-contact-button',
        icon: `
        <span class='nd-action-button invite-to-conference-icon'>
          <span class="xivo-phone-plus-wrapper">
            <span><i class="xivo-telephone"></i></span>
            <i class="fa fa-plus xivo-phone-plus" aria-hidden="true"></i>
            </i>
          </span>
        </span>`,
        title: 'INVITE_TO_CONFERENCE',
        onClick: (contact, $event) => {
          $event.stopPropagation();
          ContactService.inviteToConference(contact, $event);
          $scope.contactSheetOnClose();
        },
        displayCondition: (contact) => !isNil(contact.actions[ContactSheetActionEnum.AudioInvite]) && ContactService.userWithInviteToConferenceIcon(contact) || ContactService.meetingRoomWithInviteToConferenceIcon(contact),
      }
    };

    $scope.ordering = ['Edit', 'ShareLink', 'Mail', 'Chat', 'VideoInvite', 'Video', 'AudioInvite', 'Call'];

  }
}
